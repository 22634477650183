<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p class="is-size-7">Copyright &copy; {{ currentYear }} Working Unit Tests. All Rights Reserved</p>
    </div>
  </footer>
</template>

<script>
  export default {
    computed: {
      currentYear() {
        return new Date().getFullYear();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .footer {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
</style>
